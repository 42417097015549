import { useState } from "react";

export default function App() {
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const yesButtonSize = noCount * 20 + 16;

  const handleNoClick = () => {
    setNoCount(noCount + 1);
  };

  const getNoButtonText = () => {
    const phrases = [
      "No",
      "Are you sure?",
      "Really sure?",
      "There's no way!",
      "Stop it idot 😡",
      "Come ooon just say yes",
      "Caitlyn will hate you if you don't !",
      "I promise you'll get lychees",
      "I'll never make you watch Mamma Mia again",
      "Free back massages for life!!!",
      "Just press Yes alreadyyy",
      "I'm running out of ideas on what to write here",
      "I'll take you to see the Northern Lights!",
      "I'll start walking fasterrr",
      "I'll fill out all your visas in the future",
      "Four-course with Daddy!!!",
      "Stop making this so haaard",
      "Last chance!",
      "Okay second last chance",
      "This is the last chance!",
      "Aight guess I'll go gym and become big af 🤷‍♂️"
    ];

    return phrases[Math.min(noCount, phrases.length - 1)];
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen -mt-16">
      {yesPressed ? (
        <>
        <img src="https://i.giphy.com/1gv7WwUYJlaRKWVtok.webp" />
        <div className="text-4xl font-bold my-4">Yessss now come outsiiide 👀</div>
        </>
      ) : (
        <>
          <img className="h-[200px]" src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWU1Y3hmd3A0MTJyZHo3dHU5MWdna3BpMGpid2Rpc2wwYmozcnJkciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l3q2vScRNubmJGSUo/giphy.gif" />
          <h1 className="text-4xl my-4">Will you be my Valentine?</h1>
          <div>
            <button
              className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4`}
              style={{ fontSize: yesButtonSize }}
              onClick={() => setYesPressed(true)}
            >
              Yes
            </button>
            <button
              onClick={handleNoClick}
              className=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              {noCount === 0 ? "No" : getNoButtonText()}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
